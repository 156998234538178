/* Base container styles */

.prompt-container {
    display: grid;
    grid-template-rows: 2fr 3fr 4fr 1fr;
    grid-template-columns: repeat(5, 1fr);
    justify-items: center;
    align-items: center;
    width: 70vw;
    height: 80vh;
    padding: 1vw;
    margin: 3rem auto;
    background: linear-gradient(to right, rgba(72, 1, 126, 0.45), rgba(24, 0, 94, 0.45));
    border-radius: 2rem;
    border: 3px solid black;
  }
  
  /* Text Styles */
  h1 {
    grid-column: 1 / -1;
    grid-row: 1;
    font-size: 5em; /* Consider using vw for responsiveness */
    justify-content: center;
    color: #fff;
    text-shadow: 0 0 8px #4f056a, 0 0 16px #666666, 0 0 24px #666;
    animation: glow 1.5s ease-in-out infinite alternate;
  }
  
  p {
    grid-column: 1 / -1;
    grid-row: 2;
    text-align: center;
    font-size: 1.5vw;
    justify-content: center;
    margin: 2rem;
    color: rgb(245, 162, 248);
  }
  
  h2 {
    color: whitesmoke;
    text-align: center;
    text-shadow: 0 0 8px #666, 0 0 16px #666, 0 0 24px #666;
    animation: glow 1.5s ease-in-out infinite alternate;
  }
  
  /* Animation Keyframes */
  @keyframes glow {
    from {
      text-shadow: 0 0 8px #666, 0 0 16px #666, 0 0 24px #666;
    }
    to {
      text-shadow: 0 0 8px #999, 0 0 16px #999, 0 0 24px #999;
    }
  }
  
  /* Image Container Styles */
  .image-container {
    display: grid;
    grid-template-rows: .8fr .2fr;
    justify-content: center;
    align-items: center;
  }
  
  .image-container.first {
    grid-column: 2;
  }
  
  .image-container.second {
    grid-column: 4;
  }
  
  .images {
    grid-row: 1;
    width: 13vw;
    height: auto; /* Changed to maintain aspect ratio */
    object-fit: cover;
    border-radius: 10px;
    
  }
  
  /* Arrow Styles */
  .arrow-container {
    grid-row: 3;
    grid-column: 3;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .arrow {
    border: solid rgb(120, 126, 184);
    border-width: 0 8px 8px 0;
    padding: 10px;
    transform: rotate(-45deg);
    animation: pop 1.5s infinite;
  }
  
  @keyframes pop {
    0%, 20%, 50%, 80%, 100% {
      transform: translateX(0) rotate(-45deg);
    }
    40% {
      transform: translateX(-20px) rotate(-45deg);
    }
    60% {
      transform: translateX(-10px) rotate(-45deg);
    }
  }
  
  /* Button Styles */
  .centered-button {
    grid-row: 4;
    grid-column: 3;
    padding: 1em;
    border-radius: 5px;
    font-size: auto; /* Consider using em or px to define font-size */
    cursor: pointer;
    background-color: #7701BC;
    transition: background-color 0.2s;
    color: white;
    white-space: nowrap;
    width: 60%;
    height: 80%;
    margin: auto;
    text-align: center;
    box-sizing: border-box;
  }
  
  .centered-button:hover {
    background-color: #b73bff;
  }
  
  
  /*Next Page button*/
  .nextpagebtn-container{
    grid-row: 4;
    grid-column: 5;
  }
  
  