#star-animation-container {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1; /* Ensure the background is behind other content */
    background: linear-gradient(90deg, rgb(0, 0, 0) 0%, rgb(39, 55, 75) 45%, #3e3043 75%);
    display: flex;
    justify-content: center;
    align-items: center;
}
.centered-container {
    position: relative;
    width: 100%;
    height: 100vh;
}

.letter {
    position: absolute;
    color: #37F0FF; 
    font-family: 'Seria', monospace;
    font-size: 10px; 
    border-radius: 50%;
    user-select: none;
    z-index: -1;
    animation: zoomLetter;
}

@keyframes zoomLetter {
    0% {
        opacity: 1;
        transform: scale(2);
    }
    100% {
        opacity: 0;
        transform: scale(0);
    }
}

